import {configureStore} from '@reduxjs/toolkit'
import {setupListeners} from "@reduxjs/toolkit/query";
import {getCategoryReducers} from "../features/categories/reducers";
import {getWalletsReducers} from "../features/wallets/reducers";
import {getMovementsReducers} from "../features/movements/reducers";
import {getExpensesReducers} from "../features/expenses/reducers";

const rootReducer = {
    ...getCategoryReducers(),
    ...getWalletsReducers(),
    ...getMovementsReducers(),
    ...getExpensesReducers()
}

export const store = configureStore({
    reducer: rootReducer
})

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch