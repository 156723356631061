import {walletsRoute} from "../constants";
import {getApi} from "../get";
import {postApi} from "../post";
import {deleteApi} from "../delete";
import {putApi} from "../put";
import {Wallet} from "../../types/Wallet";

export const getWalletsApi = async (params?: any): Promise<Wallet[]> => {
    return await getApi(walletsRoute, params);
}

export const addWalletApi = async (category: Wallet): Promise<void> => {
    return await postApi(walletsRoute, category);
}

export const deleteWalletApi = async (id: string): Promise<void> => {
    return await deleteApi(`${walletsRoute}/${id}`);
}

export const getWalletApi = async (id: string): Promise<Wallet> => {
    return await getApi(`${walletsRoute}/${id}`);
}

export const updateWalletApi = async (id: string, category: Wallet): Promise<void> => {
    return await putApi(walletsRoute, id, category);
}

