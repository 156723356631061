import React from "react";
import {useAppAsyncDispatch} from "../../hooks/useAppAsyncDispatch";
import {Modal} from "antd";
import {createMovement, initCreateMovement} from "../../features/movements/create";
import {initUpdateMovement, updateMovement} from "../../features/movements/update";
import {Movement} from "../../types/Movement";
import MovementForm from "./MovementForm";
import {RootState} from "../../store/store";
import useModalCreateOrUpdate from "../../hooks/useModalCreateOrUpdate";

type MovementsModalProps = {
    isOpen: boolean,
    editItem: Movement | null,
    onCancel: () => void,
    onCompleted: () => void
}
const MovementsModal = (
    {
        isOpen,
        editItem,
        onCancel,
        onCompleted
    }: MovementsModalProps) => {
    const dispatch = useAppAsyncDispatch();
    const dispatchCreateMovement = (data: any) => dispatch(createMovement(data))
    const dispatchUpdateMovement = (id: string, data: Movement) => dispatch(updateMovement({id, data}))

    useModalCreateOrUpdate(
        (state: RootState) => state.createMovement.status,
        (state: RootState) => state.createMovement.error,
        initCreateMovement,
        onCompleted,
        'Movement created'
    );

    useModalCreateOrUpdate(
        (state: RootState) => state.updateMovement.status,
        (state: RootState) => state.updateMovement.error,
        initUpdateMovement,
        onCompleted,
        'Movement updated'
    );

    return (
        <Modal open={isOpen}
               closable={false}
               footer={null}
               title={editItem ? 'Update movement' : 'Create a new movement'}>
            <MovementForm onClose={onCancel}
                          onCreate={dispatchCreateMovement}
                          onUpdate={dispatchUpdateMovement}
                          item={editItem}/>
        </Modal>
    )
}

export default MovementsModal;