export const updateParams = (params: any, newParams: any): any => {
    const filterIsNullOrEmpty = (value: any) => value === null || value === undefined || value === '';

    let returnValue = {...params};
    Object.keys(newParams).forEach(key => {
        if (filterIsNullOrEmpty(newParams[key])) {
            delete returnValue[key];
        } else {
            returnValue = {...returnValue, [key]: newParams[key]};
        }
    })

    return returnValue;
}

export const paramsToQueryParams = (params: any): string => {
    if (!params) {
        return '';
    }
    const urlParams = new URLSearchParams();
    Object.keys(params).forEach(key => {
        console.log(key);
        console.log(params[key]);
        if (params[key] !== null && params[key] !== undefined)
            urlParams.append(key, params[key]);
    });
    return `?${urlParams.toString()}`;
};