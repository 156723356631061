import {WalletType} from "../../types/Wallet";
import {Space, TableProps} from "antd";
import TableUpdateButton from "../../components/TableUpdateButton";
import TableDeleteButton from "../../components/TableDeleteButton";
import React from "react";

type WalletsTableColumnsProps = {
    onDelete(id: string): void,
    onEdit(id: string): void
}
const WalletsColumns = (
    {onDelete, onEdit}: WalletsTableColumnsProps
): TableProps<{}>['columns'] => {

    return [
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (type: string) => {
                let walletType;
                switch (type) {
                    case WalletType.CASH:
                        walletType = 'Cash';
                        break;
                    case WalletType.BANK:
                        walletType = 'Bank Account';
                        break;
                    case WalletType.CREDIT_CARD:
                        walletType = 'Credit Card';
                        break;
                    default:
                        walletType = 'Unknown';
                        break;
                }
                return walletType;
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Starting Balance',
            dataIndex: 'startingBalance',
            key: 'startingBalance',
            align: 'right' as const,
            render: (balance: number) => {
                return new Intl.NumberFormat('it-IT', {
                    style: 'currency',
                    currency: 'EUR'
                }).format(balance);
            }
        },
        {
            title: 'Current Balance',
            dataIndex: 'balance',
            key: 'balance',
            align: 'right' as const,
            render: (balance: number) => {
                const formattedAmount = new Intl.NumberFormat('it-IT', {
                    style: 'currency',
                    currency: 'EUR'
                }).format(balance);
                return <span
                    style={{fontWeight: 'bold', color: balance > 0 ? 'green' : 'red'}}>{formattedAmount}</span>;
            }
        },
        {
            title: '',
            width: 100,
            key: 'action-delete',
            render: (record: any) => (
                <Space size="middle">
                    <TableUpdateButton action={() => onEdit(record.id)}
                                       record={record}
                                       dataTestId={`edit-wallet-${record.id}`}
                    />
                    <TableDeleteButton record={record}
                                       action={() => onDelete(record.id)}
                                       disabled={record.movementsCount > 0}
                                       dataTestId={`delete-wallet-${record.id}`}
                    />
                </Space>
            ),
        }
    ];
}

export default WalletsColumns;

