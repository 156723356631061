import fetchMovementsReducer from "./getAll";
import createMovementReducer from "./create";
import updateMovementReducer from "./update";
import deleteMovementReducer from "./delete";
import crudMovementsReducer from "./crud";

export const getMovementsReducers = () => ({
    fetchMovements: fetchMovementsReducer,
    createMovement: createMovementReducer,
    updateMovement: updateMovementReducer,
    deleteMovement: deleteMovementReducer,
    deleteMovementsByIds: deleteMovementReducer,
    crudMovement: crudMovementsReducer
});