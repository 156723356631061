import React, {useEffect, useState} from "react"
import {Button, Form, Select, Upload} from "antd"
import {Wallet} from "../../types/Wallet";
import {UploadOutlined} from "@ant-design/icons";
import {getWalletsApi} from "../../api/entities/wallets";

type ImportMovementsFormProps = {
    onConfirm: (data: any) => void;
    onCancel: () => void;
}

const ImportMovementsForm: React.FC<ImportMovementsFormProps> = (
    {
        onConfirm,
        onCancel
    }
) => {
    const [form] = Form.useForm();
    const [wallets, setWallets] = useState<Wallet[]>();

    const close = () => {
        form.resetFields();
        onCancel();
    }

    const onFinish = async (data: any) => {
        onConfirm(data);
    }

    const onFinishFailed = (e: any) => {
        console.log('############### Failed', e);
    }

    const fetchWallets = async () => {
        const wallets = await getWalletsApi({type: 'BANK'});
        setWallets(wallets);
    }

    useEffect(() => {
        fetchWallets().then();
    }, []);

    return (
        <Form form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              labelCol={{span: 8}}
              wrapperCol={{span: 16}}
              style={{maxWidth: 600, marginTop: '24px'}}
        >
            <Form.Item label="Wallet" name="walletId" rules={[{required: true, message: 'Please select a wallet'}]}>
                <Select data-testid='input-import-movement-wallet' placeholder='Select the wallet'>
                    {wallets?.map((wallet: Wallet) => (
                        <Select.Option key={wallet.id} value={wallet.id} data-testid={wallet.id}>
                            {wallet.name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item label="Upload Excel" name="excelFile" data-testid="form-item-upload-excel"
                       rules={[{required: true, message: 'Please upload an Excel file'}]}>
                <Upload accept=".xlsx, .xls" beforeUpload={() => false} data-testid="file-input">
                    <Button icon={<UploadOutlined/>}>Click to Upload</Button>
                </Upload>
            </Form.Item>
            <Form.Item wrapperCol={{offset: 8, span: 16}} style={{marginBottom: 0}}>
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button style={{marginRight: '8px'}} onClick={close}>Cancel</Button>
                    <Button type="primary" htmlType="submit" data-testid="btn-confirm-import">Import</Button>
                </div>
            </Form.Item>
        </Form>
    )
}

export default ImportMovementsForm