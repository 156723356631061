import React from 'react';

export type FMTableActionProps = {
    action: (id: string) => void;
    record: any,
    dataTestId?: string,
    icon?: any,
    disabled?: boolean
}

export default function TableButton(
    {
        record,
        action,
        dataTestId,
        icon,
        disabled
    }: FMTableActionProps) {
    return (
        <div onClick={(e) => {
            if (disabled) return;
            e.stopPropagation();
            action(record.id)
        }}
             style={{
                 cursor: disabled ? 'not-allowed' : 'pointer',
                 opacity: disabled ? 0.5 : 1
             }}
             data-testid={dataTestId}
             aria-disabled={disabled}
        >
            {icon}
        </div>

    )
}