import React, {useEffect, useRef} from "react"
import {Button, Form, Input, InputNumber, InputRef, Select} from "antd"
import {MenuItemType} from "antd/es/menu/interface";
import {WalletType} from "../../types/Wallet";
import {FormComponentProps} from "../../components/FormComponentProps";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";

const WalletForm: React.FC<FormComponentProps> = (
    {
        item,
        onCreate,
        onUpdate,
        onClose
    }
) => {
    const [form] = Form.useForm();
    const nameRef = useRef<InputRef>(null);
    const createWalletState = useSelector((state: RootState) => state.createWallet.status);
    const updateWalletState = useSelector((state: RootState) => state.updateWallet.status);

    const walletTypeOptions: MenuItemType[] = Object.entries(WalletType).map(([key, value]) => ({
        label: key,
        value: value.toString(),
        key: value.toString()
    }));

    const initialValues = {
        type: WalletType.CASH,
        startingBalance: 0,
        balance: 0
    };

    const onFinish = async (data: any) => {
        if (item) {
            onUpdate(item.id!, data)
        } else {
            onCreate(data);
        }
    }

    const close = () => {
        form.resetFields();
        onClose();
    }

    useEffect(() => {
        if (createWalletState == 'COMPLETED' || updateWalletState == 'COMPLETED')
            form.resetFields();
    }, [createWalletState, updateWalletState]);

    useEffect(() => {
        form.resetFields()
        if (item) {
            form.setFieldsValue(item);
        }
    }, [item, form]);

    return (
        <Form labelCol={{span: 8}}
              wrapperCol={{span: 16}}
              style={{maxWidth: 600, marginTop: '24px'}}
              initialValues={item ? item : initialValues}
              onFinish={onFinish}
              form={form}>
            <Form.Item label="Wallet Type" name="type" rules={[{required: true}]}>
                <Select data-testid='input-wallet-type' placeholder='Select the wallet type'
                        options={walletTypeOptions}/>
            </Form.Item>
            <Form.Item label="Name"
                       name="name"
                       rules={[{required: true, message: 'Wallet name is required'}]}>
                <Input data-testid="input-wallet-name" ref={nameRef}/>
            </Form.Item>
            <Form.Item label="Starting Balance"
                       name="startingBalance">
                <InputNumber<number>
                    addonAfter={'€'}
                    data-testid="input-wallet-starting-balance"
                />
            </Form.Item>
            <Form.Item wrapperCol={{offset: 8, span: 16}} style={{marginBottom: 0}}>
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button style={{marginRight: '8px'}} onClick={close}>Cancel</Button>
                    <Button type="primary" htmlType="submit" data-testid="btn-confirm">Confirm</Button>
                </div>
            </Form.Item>
        </Form>
    )
}

export default WalletForm