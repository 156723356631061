import {ExpensesTable} from "./ExpensesTable";
import {useAppAsyncDispatch} from "../../hooks/useAppAsyncDispatch";
import {fetchExpensesGroupByCategory} from "../../features/expenses/getAll";
import {useEffect} from "react";
import {useSelector} from "react-redux";

const ExpensesPage = () => {

    const dispatch = useAppAsyncDispatch();
    const isLoading = useSelector((state: any) => state.fetchExpensesGroupByCategory.status === 'RUNNING');
    const data = useSelector((state: any) => state.fetchExpensesGroupByCategory.data);
    const fetchExpenses = () => dispatch(fetchExpensesGroupByCategory({}));
    useEffect(() => {
        fetchExpenses();
    }, []);

    return (
        <div>
            <ExpensesTable isLoading={isLoading} data={data}/>
        </div>
    );
}

export default ExpensesPage;