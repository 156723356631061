import axios from "axios";

export type ApiError = {
    status: number;
    code: string;
    message: string;
}

export function axiosToApiError(error: any): ApiError | any {
    console.log(error);
    if (axios.isAxiosError(error)) {
        return {
            code: error.response?.data.code,
            status: error.response?.status,
            message: error.response?.data.message,
            data: {
                code: error.response?.data.code,
                message: error.response?.data.message,
            },
        };
    } else {
        return {code: "GENERIC_ERROR", message: "Generic server error"};
    }
}