import React, {useEffect, useState} from "react"
import {Button, Form, message, Select} from "antd"
import {Category} from "../../types/Category";
import {getCategoriesApi} from "../../api/entities/categories";
import {updateCategoryMovementsApi} from "../../api/entities/movements";


type MovementChangeCategoryFormProps = {
    ids: string[],
    onCompleted(): void,
    onClose(): void,
}
const MovementsChangeCategoryForm: React.FC<MovementChangeCategoryFormProps> = (
    {
        ids,
        onCompleted,
        onClose
    }
) => {
    const [form] = Form.useForm();
    const [categories, setCategories] = useState<Category[]>();

    const close = () => {
        form.resetFields();
        onClose();
    }

    const onFinish = async (data: any) => {
        const categoryId = data.category?.id;
        try {
            await updateCategoryMovementsApi(ids, categoryId);
            message.success('Movements updated');
            form.resetFields();
            onCompleted();
        } catch (err: any) {
            message.error(err.message);
        }
    }

    const fetchCategories = async () => {
        const categories = await getCategoriesApi();
        setCategories(categories);
    }

    useEffect(() => {
        fetchCategories().then();
    }, []);

    const categoriesOptions = categories?.map((category: Category) => (
        <Select.Option key={category.id} value={category.id} data-testid={category.id}>
            {category.name}
        </Select.Option>
    ))

    return (
        <Form labelCol={{span: 8}}
              wrapperCol={{span: 16}}
              style={{maxWidth: 600, marginTop: '24px'}}
              onFinish={onFinish}
              form={form}>
            <Form.Item label="Category" name={["category", "id"]}
                       rules={[{required: true, message: 'Category is required'}]}>
                <Select data-testid='input-movement-category'
                        placeholder='Select the category'>
                    <Select.Option key="none" value={"none"}>None</Select.Option>
                    {categoriesOptions}
                </Select>
            </Form.Item>
            <Form.Item wrapperCol={{offset: 8, span: 16}} style={{marginBottom: 0}}>
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button style={{marginRight: '8px'}} onClick={close}>Cancel</Button>
                    <Button type="primary" htmlType="submit" data-testid="btn-confirm">Confirm</Button>
                </div>
            </Form.Item>
        </Form>
    )
}

export default MovementsChangeCategoryForm