import React, {PropsWithChildren} from "react";

type ToolbarProps = PropsWithChildren

export default function Toolbar(props: ToolbarProps) {

    return (
        <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '20px'}}>
            {props.children}
        </div>
    );
}