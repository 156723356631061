import {movementsRoute} from "../constants";
import {getApi} from "../get";
import {postApi} from "../post";
import {deleteApi} from "../delete";
import {putApi, putApiWithoutId} from "../put";
import {Movement} from "../../types/Movement";
import {upload} from "../putWithForm";

export const getMovementsApi = async (params: any): Promise<any> => {
    console.log('getMovementsApi' + params);
    return await getApi(movementsRoute, params);
}

export const addMovementApi = async (category: Movement): Promise<void> => {
    return await postApi(movementsRoute, category);
}

export const deleteMovementApi = async (id: string): Promise<void> => {
    return await deleteApi(`${movementsRoute}/${id}`);
}

export const deleteMovementsByIdsApi = async (ids: string[]): Promise<void> => {
    return await deleteApi(`${movementsRoute}?${ids.map(id => `id=${id}`).join('&')}`);
}

export const getMovementApi = async (id: string): Promise<Movement> => {
    return await getApi(`${movementsRoute}/${id}`);
}

export const updateMovementApi = async (id: string, movement: Movement): Promise<void> => {
    return await putApi(movementsRoute, id, movement);
}

export const updateCategoryMovementsApi = async (ids: string[], categoryId: String): Promise<void> => {
    return await putApiWithoutId(movementsRoute, {ids, categoryId});
}


export const uploadExcelApi = async (form: any): Promise<void> => {
    return await upload(`${movementsRoute}/upload`, form);
}

