import {categoriesRoute} from "../constants";
import {Category} from "../../types/Category";
import {getApi} from "../get";
import {postApi} from "../post";
import {deleteApi} from "../delete";
import {putApi} from "../put";

export const getCategoriesApi = async (params?: any): Promise<Category[]> => {
    return await getApi(categoriesRoute, params);
}

export const addCategoryApi = async (category: Category): Promise<void> => {
    return await postApi(categoriesRoute, category);
}

export const deleteCategoryApi = async (id: string): Promise<void> => {
    return await deleteApi(`${categoriesRoute}/${id}`);
}

export const getCategoryApi = async (id: string): Promise<Category> => {
    return await getApi(`${categoriesRoute}/${id}`);
}

export const updateCategoryApi = async (id: string, category: Category): Promise<void> => {
    return await putApi(categoriesRoute, id, category);
}

