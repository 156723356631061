import React, {useEffect, useState} from "react"
import MovementsTable from "./MovementsTable";
import {Button, message} from "antd";
import Toolbar from "../../components/Toolbar";
import {useAppAsyncDispatch} from "../../hooks/useAppAsyncDispatch";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {fetchMovements} from "../../features/movements/getAll";
import {ApiStatus} from "../../types/ApiStatus";
import {updateParams} from "../../utils/params";
import {ImportMovementModal} from "./ImportMovementModal";
import {deleteMovementsByIdsApi, getMovementApi} from "../../api/entities/movements";
import MovementsModal from "./MovementsModal";
import {confirmDelete} from "../../utils/confirmDelete";
import MovementsChangeCategoryModal from "./MovementsChangeCategoryModal";

const MovementsPage = () => {

    const dispatch = useAppAsyncDispatch()
    const pagedMovements = useSelector((state: RootState) => state.fetchMovements.data);
    const isLoading = useSelector((state: RootState) => state.fetchMovements.status === ApiStatus.RUNNING);
    const [isOpen, setIsOpen] = useState(false);
    const [isImportOpen, setIsImportOpen] = useState(false);
    const [isChangeCategoryOpen, setIsChangeCategoryOpen] = useState(false);
    const [item, setItem] = useState<any>(null);
    const [params, setParams] = useState<any>({page: 1, pageSize: 10});
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    const fetch = (params?: any) => dispatch(fetchMovements(params));

    const openModalForEdit = async (id: string) => {
        const movement = await getMovementApi(id);
        setItem(movement);
        setIsOpen(true);
    }

    const openModalForImport = () => {
        setIsImportOpen(true);
    }

    const openModalForChangingCategory = () => {
        setIsChangeCategoryOpen(true);
    }

    const updateFiltersAndPagination = (newParams: any) => {
        setParams(updateParams(params, newParams));
    }

    const openModalForCreate = () => {
        setItem(null);
        setIsOpen(true);
    }

    const closeImportModal = () => {
        setIsImportOpen(false);
    }

    const closeModal = () => {
        setIsOpen(false);
    }

    const closeChangeCategoryModal = () => {
        setIsChangeCategoryOpen(false);
    }

    const onCompleted = () => {
        setIsOpen(false);
        setIsImportOpen(false);
        setIsChangeCategoryOpen(false);
        setSelectedRowKeys([]);
        fetch(params);
    }

    const rowSelectionChange = (selectedRowKeys: any[]) => {
        setSelectedRowKeys(selectedRowKeys);
    }

    const confirmDeleteSelected = () => {
        confirmDelete("Are you sure to delete selected movements?", async () => {
            try {
                await deleteMovementsByIdsApi(selectedRowKeys);
                message.success('Movements deleted');
                setSelectedRowKeys([]);
                fetch(params);
            } catch (err: any) {
                message.error(err.message);
            }
        });
    }

    useEffect(() => {
        fetch(params);
    }, []);

    useEffect(() => {
        fetch(params);
    }, [params]);


    return (
        <div>
            <Toolbar>
                {(selectedRowKeys.length > 0) && <Button type="primary" danger data-testId="button-delete-selected"
                                                         style={{marginLeft: '10px'}}
                                                         onClick={confirmDeleteSelected}>Delete Selected</Button>}
                {(selectedRowKeys.length > 0) && <Button type="primary" data-testId="button-change-category"
                                                         style={{marginLeft: '10px'}}
                                                         onClick={openModalForChangingCategory}>Set category</Button>}
                <Button type="primary" data-testId="button-create-new" style={{marginLeft: '10px'}}
                        onClick={openModalForCreate}>New movement</Button>
                <Button type="primary" data-testId="btn-import-movements" style={{marginLeft: '10px'}}
                        onClick={openModalForImport}>Import movements</Button>
            </Toolbar>
            <MovementsTable isLoading={isLoading}
                            pagedMovements={pagedMovements}
                            onEdit={openModalForEdit}
                            onDeleted={() => fetch(params)}
                            onChange={updateFiltersAndPagination}
                            rowSelection={{
                                selectedRowKeys,
                                onChange: rowSelectionChange
                            }}
            />
            <MovementsModal isOpen={isOpen} editItem={item} onCancel={closeModal} onCompleted={onCompleted}/>
            <ImportMovementModal isOpen={isImportOpen} onCancel={closeImportModal} onCompleted={onCompleted}/>
            <MovementsChangeCategoryModal isOpen={isChangeCategoryOpen} onCancel={closeChangeCategoryModal}
                                          ids={selectedRowKeys}
                                          onCompleted={onCompleted}/>
        </div>
    )
}

export default MovementsPage;