import React, {useEffect, useState} from 'react';
import CategoriesTable from "./CategoriesTable";
import Toolbar from "../../components/Toolbar";
import CategoriesModal from "./CategoriesModal";
import {Button, message} from "antd";
import {useAppAsyncDispatch} from "../../hooks/useAppAsyncDispatch";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {ApiStatus} from "../../types/ApiStatus";
import {getCategoryApi} from "../../api/entities/categories";
import {fetchCategories} from "../../features/categories/getAll";


export default function CategoriesPage() {

    const dispatch = useAppAsyncDispatch()
    const [isOpen, setIsOpen] = useState(false);
    const [item, setItem] = useState<any>(null);
    const isLoading = useSelector((state: RootState) => state.fetchCategories.status === ApiStatus.RUNNING);
    const categories = useSelector((state: RootState) => state.fetchCategories.data);
    const fetch = () => dispatch(fetchCategories({}));
    const openModalForEdit = async (id: string) => {

        try {
            const wallet = await getCategoryApi(id);
            setItem(wallet);
            setIsOpen(true);
        } catch (err: any) {
            message.error(err.message);
        }
    }
    const openModalForCreate = () => {
        setItem(null);
        setIsOpen(true);
    }
    const closeModal = () => {
        setItem(null);
        setIsOpen(false);
    }
    const onCompleted = () => {
        closeModal();
        fetch();
    }
    const onChange = (changes: any) => {
        dispatch(fetchCategories(changes));
    }

    useEffect(() => {
        fetch();
    }, []);

    return (
        <div>
            <Toolbar>
                <Button type="primary" data-testId="button-create-new"
                        onClick={openModalForCreate}>New category</Button>
            </Toolbar>
            <CategoriesTable onEdit={openModalForEdit} onDeleted={onCompleted} categories={categories}
                             isLoading={isLoading} onChange={onChange}/>
            <CategoriesModal onCompleted={onCompleted} isOpen={isOpen} editItem={item} onCancel={closeModal}/>
        </div>
    );
}