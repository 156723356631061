import axios from "axios";
import {axiosToApiError} from "../types/ApiError";
import {auth} from "../firebase";

export const deleteApi = async (url: string): Promise<void> => {
    try {
        const authHeader = await auth.currentUser?.getIdToken();
        const apiHeaders = {Authorization: `Bearer ${authHeader}`}
        const response = await axios.delete(url, {headers: apiHeaders});
        return response.data;
    } catch (error: any) {
        throw axiosToApiError(error);
    }
}