import React, {useEffect, useState} from "react"
import {Button, DatePicker, Form, Input, InputNumber, Select} from "antd"
import {Wallet, WalletType} from "../../types/Wallet";
import {FormComponentProps} from "../../components/FormComponentProps";
import dayjs from "dayjs";
import {Movement} from "../../types/Movement";
import {Category} from "../../types/Category";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {ApiStatus} from "../../types/ApiStatus";
import {getWalletsApi} from "../../api/entities/wallets";
import {getCategoriesApi} from "../../api/entities/categories";

const {TextArea} = Input

const MovementForm: React.FC<FormComponentProps> = (
    {
        item,
        onCreate,
        onUpdate,
        onClose
    }
) => {
    const [form] = Form.useForm();
    const createMovementState = useSelector((state: RootState) => state.createMovement.status);
    const updateMovementState = useSelector((state: RootState) => state.updateMovement.status);
    const [wallets, setWallets] = useState<Wallet[]>();
    const [categories, setCategories] = useState<Category[]>([]);

    const prepareDataToSave = (data: any) => {
        const movement = {
            ...data,
            date: data.date.format('YYYY-MM-DD'),
            walletId: data.wallet.id,
            categoryId: data.category?.id
        }
        delete movement.wallet;
        delete movement.category;
        return movement;
    }

    const editValues = (movement: Movement) => {
        return {
            ...movement,
            date: dayjs(movement.date)
        }
    }

    const initialValues = {
        date: dayjs(),
        amount: 0
    }

    const close = () => {
        form.resetFields();
        onClose();
    }

    const onFinish = async (data: any) => {
        const value = prepareDataToSave(data);
        if (item) {
            onUpdate(item.id!, value)
        } else {
            onCreate(value);
        }
    }

    const isImported = () => {
        return item !== null && item.imported;
    }

    const walletTypeFilter = () => {
        return item !== null ? {} : {type: WalletType.CASH};
    }

    const fetchCategories = async () => {
        setCategories(await getCategoriesApi());
    }

    const fetchWallets = async () => {
        setWallets(await getWalletsApi(walletTypeFilter()));
    }

    useEffect(() => {
        if (createMovementState == ApiStatus.COMPLETED || updateMovementState == ApiStatus.COMPLETED)
            form.resetFields();
    }, [createMovementState, updateMovementState]);

    useEffect(() => {
        form.resetFields()
        if (item) {
            form.setFieldsValue(editValues(item));
        }
        fetchWallets().then();
    }, [item, form]);

    useEffect(() => {
        fetchCategories().then();
    }, []);

    const categoriesOptions = [
        {value: null, label: 'None'},
        ...categories.map((category: Category) => (
            {value: category.id, label: category.name}
        ))
    ];

    const walletOptions = wallets?.map((wallet: Wallet) => (
        {value: wallet.id, label: `${wallet.name} (${wallet.type})`}
    ));

    return (
        <Form labelCol={{span: 8}}
              wrapperCol={{span: 16}}
              style={{maxWidth: 600, marginTop: '24px'}}
              initialValues={item ? editValues(item) : initialValues}
              onFinish={onFinish}
              form={form}>
            <Form.Item label="Wallet" name={["wallet", "id"]}
                       rules={[{required: true, message: 'Movement wallet is required'}]}>
                <Select data-testid='input-movement-wallet'
                        disabled={item !== null}
                        options={walletOptions}
                        placeholder='Select the wallet'>
                </Select>
            </Form.Item>
            <Form.Item label="Date"
                       name="date"
                       rules={[{required: true, message: 'Movement date is required'}]}>
                <DatePicker data-testid="input-movement-date" disabled={isImported()}/>
            </Form.Item>
            <Form.Item label="Amount"
                       name="amount"
                       rules={[{required: true, message: 'Movement amount is required'}]}>
                <InputNumber<number>
                    addonAfter={'€'}
                    data-testid="input-movement-amount"
                    disabled={isImported()}
                    formatter={value => new Intl.NumberFormat('it-IT', {style: 'decimal'}).format(value as number)}
                    parser={value => value ? parseFloat(value.replace(/\./g, '').replace(',', '.')) : 0}
                />
            </Form.Item>
            <Form.Item label="Description"
                       name="description"
                       rules={[{required: true, message: 'Movement description is required'}]}>
                <Input data-testid="input-movement-description" disabled={isImported()}/>
            </Form.Item>
            <Form.Item label="Notes"
                       name="notes">
                <TextArea rows={4} data-testid="input-movement-notes"/>
            </Form.Item>
            <Form.Item label="Category" name={["category", "id"]}>
                <Select data-testid='input-movement-category'
                        showSearch={true}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        placeholder='Select the category'
                        options={categoriesOptions}>
                </Select>
            </Form.Item>
            <Form.Item wrapperCol={{offset: 8, span: 16}} style={{marginBottom: 0}}>
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button style={{marginRight: '8px'}} onClick={close}>Cancel</Button>
                    <Button type="primary" htmlType="submit" data-testid="btn-confirm">Confirm</Button>
                </div>
            </Form.Item>
        </Form>

    )
}

export default MovementForm