import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider,} from "react-router-dom";
import LayoutPage from './pages/LayoutPage';
import ErrorPage from './pages/ErrorPage';
import WalletsPage from './pages/wallets/WalletsPage';
import MovementsPage from "./pages/movements/MovementsPage";
import CategoriesPage from "./pages/categories/CategoriesPage";
import {Provider} from "react-redux";
import {store} from "./store/store";
import ExpensesPage from "./pages/expenses/ExpensesPage";
import LoginPage from "./pages/auth/LoginPage";
import {AuthProvider} from "./context/AuthContext";
import PrivateRoute from "./components/PrivateRoute";

const router = createBrowserRouter([
    {
        path: "/login",
        element: <LoginPage/>,
        errorElement: <ErrorPage/>,
    },
    {
        path: "/",
        element: (
            <PrivateRoute>
                <LayoutPage/>
            </PrivateRoute>
        ),
        children: [
            {path: "", element: <div>Home page</div>},
            {path: "wallets", element: <WalletsPage/>},
            {path: "movements", element: <MovementsPage/>},
            {path: "categories", element: <CategoriesPage/>},
            {path: "expenses", element: <ExpensesPage/>},
            {path: "*", element: <ErrorPage/>},
        ],
        errorElement: <ErrorPage/>,
    },
]);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <Provider store={store}>
        <AuthProvider>
            <RouterProvider router={router}/>
        </AuthProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
