import React, {createContext, useContext, useEffect, useState} from 'react';
import {onAuthStateChanged, signOut, User} from 'firebase/auth';
import {auth} from '../firebase';

interface AuthContextProps {
    currentUser: User | null;
    loading?: boolean;
    logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextProps>({
    currentUser: null, loading: true, logout: async () => {
    }
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const [currentUser, setCurrentUser] = useState<User | null>(null);
    const [loading, setLoading] = useState(true);

    const logout = async () => {
        await signOut(auth);
        setCurrentUser(null);
    };

    useEffect(() => {
        return onAuthStateChanged(auth, (user) => {
            setCurrentUser(user);
            setLoading(false);
        });
    }, []);

    return (
        <AuthContext.Provider value={{currentUser, loading, logout}}>
            {children}
        </AuthContext.Provider>
    );
};