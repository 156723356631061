import {DatePicker, Input, Select, Space, TimeRangePickerProps} from "antd";
import {Wallet} from "../../types/Wallet";
import {Category} from "../../types/Category";
import React, {useEffect, useState} from "react";
import {debounce} from "lodash";
import dayjs from "dayjs";
import {getWalletsApi} from "../../api/entities/wallets";
import {getCategoriesApi} from "../../api/entities/categories";

type MovementsFiltersProps = {
    onChange: (changes: any) => void
}
const MovementsFilters = (
    {onChange}: MovementsFiltersProps
) => {

    const {RangePicker} = DatePicker;
    const [wallets, setWallets] = useState<Wallet[]>([]);
    const [categories, setCategories] = useState<Category[]>([]);

    useEffect(() => {

        const fetchFiltersData = async () => {
            const [walletsData, categoriesData] = await Promise.all([
                getWalletsApi({}),
                getCategoriesApi()
            ]);
            setWallets(walletsData);
            setCategories(categoriesData);
        }

        fetchFiltersData().then();
    }, []);

    const onWalletFilterChange = (value: string) => {
        onChange({walletId: value});
    }

    const onCategoryFilterChange = (value: string) => {
        onChange({categoryId: value});
    }

    const onTypeFilterChange = (value: string) => {
        onChange({type: value});
    }

    const onDescriptionFilterChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        onChange({description: e.target.value});
    }, 500);

    const onDateRangeFilterChange = (dates: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null) => {
        if (dates === null) {
            onChange({startDate: undefined, endDate: undefined});
            return;
        }
        const [start, end] = dates;
        if (start && end) {
            onChange({
                startDate: start.format('YYYY-MM-DD'),
                endDate: end.format('YYYY-MM-DD')
            });
        }
    };

    const rangePresets: TimeRangePickerProps['presets'] = [
        {label: 'Last 7 Days', value: [dayjs().add(-7, 'd'), dayjs()]},
        {label: 'Last 14 Days', value: [dayjs().add(-14, 'd'), dayjs()]},
        {label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()]},
        {label: 'Last 90 Days', value: [dayjs().add(-90, 'd'), dayjs()]},
    ];

    const categoriesOptions = [
        {value: null, label: 'All categories'},
        {value: 'none', label: 'None'},
        ...categories.map((category: Category) => (
            {value: category.id, label: category.name}
        ))
    ];

    return (
        <Space style={{marginBottom: '20px', justifyContent: 'space-between', width: '100%'}}>
            <Space>
                <Select data-testid='filter-movement-wallet'
                        style={{minWidth: '200px'}}
                        placeholder='Filter by wallet'
                        onChange={onWalletFilterChange}>
                    <Select.Option key={'null-wallet'} value={null}>All wallets</Select.Option>
                    {wallets?.map((wallet: Wallet) => (
                        <Select.Option key={wallet.id} value={wallet.id} data-testid={`filter-${wallet.id}`}>
                            {wallet.name}
                        </Select.Option>
                    ))}
                </Select>
                <Select data-testid='filter-movement-category'
                        style={{marginLeft: '20px', minWidth: '200px'}}
                        placeholder='Filter by category'
                        showSearch={true}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        onChange={onCategoryFilterChange}
                        options={categoriesOptions}>
                </Select>
                <Select data-testid='filter-movement-type'
                        style={{marginLeft: '20px', minWidth: '200px'}}
                        placeholder='Filter by type'
                        onChange={onTypeFilterChange}>
                    <Select.Option key={'ALL'} value={null}>All types</Select.Option>
                    <Select.Option key={'INCOME'} value={'INCOME'}>Incomes</Select.Option>
                    <Select.Option key={'EXPENSE'} value={'EXPENSE'}>Expenses</Select.Option>
                </Select>
                <RangePicker data-testid='filter-movement-date-range'
                             placeholder={['Start date', 'End date']}
                             style={{marginLeft: '20px'}}
                             mode={['date', 'date']}
                             presets={rangePresets}
                             onChange={onDateRangeFilterChange}/>
            </Space>

            <Input data-testid='filter-movement-description'
                   style={{marginRight: '20px', minWidth: '100px'}}
                   placeholder='Filter by description'
                   onChange={onDescriptionFilterChange}/>
        </Space>
    )
}

export default MovementsFilters;