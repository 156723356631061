import {RootState} from "../store/store";
import {ApiStatus} from "../types/ApiStatus";
import {useEffect} from "react";
import {useSelector} from "react-redux";
import {message} from "antd";
import {ApiError} from "../types/ApiError";
import {useAppAsyncDispatch} from "./useAppAsyncDispatch";

const useModalCreateOrUpdate = (
    selectStatus: (state: RootState) => ApiStatus,
    selectError: (state: RootState) => ApiError | undefined,
    resetState: () => any,
    close: () => any,
    successMessage: string) => {

    const status: ApiStatus = useSelector(selectStatus);
    const error = useSelector(selectError);
    const dispatch = useAppAsyncDispatch();

    useEffect(() => {
        const handleStatusChange = async () => {
            if (status === ApiStatus.COMPLETED) {
                dispatch(resetState());
                close();
                await message.success(successMessage);
            }
            if (status === ApiStatus.ERROR) {
                const errorResponse = error?.message;
                dispatch(resetState());
                await message.error(errorResponse);
            }
        }

        handleStatusChange().then();
    }, [status]);

    return {
        status,
        error
    };
}

export default useModalCreateOrUpdate;