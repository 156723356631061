import React from 'react';
import {Navigate} from 'react-router-dom';
import {useAuth} from '../context/AuthContext';
import {Spin} from "antd";

const PrivateRoute: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const {currentUser, loading} = useAuth();

    if (loading) {
        return <Spin/> // or a loading spinner
    }

    if (!currentUser) {
        return <Navigate to="/login"/>;
    }

    return <>{children}</>;
};

export default PrivateRoute;