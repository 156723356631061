import {ApiStatus} from "../types/ApiStatus";
import {createAsyncThunk, PayloadAction} from "@reduxjs/toolkit";
import {ApiError} from "../types/ApiError";
import {CrudMutationState} from "../types/CrudMutationState";
import {CrudFetchState} from "../types/CrudFetchState";

const crudApiMutationReducers = (builder: any, asyncThunk: any) => {
    builder.addCase(asyncThunk.pending, (state: CrudMutationState) => {
        state.status = ApiStatus.RUNNING;
    });
    builder.addCase(asyncThunk.fulfilled, (state: CrudMutationState) => {
        state.status = ApiStatus.COMPLETED;
        state.error = undefined;
    });
    builder.addCase(asyncThunk.rejected, (state: CrudMutationState, action: PayloadAction<ApiError>) => {
        state.status = ApiStatus.ERROR;
        state.error = action.payload;
    });
};

const crudApiFetchReducers = (builder: any, asyncThunk: any) => {
    builder.addCase(asyncThunk.pending, (state: CrudFetchState) => {
        state.status = ApiStatus.RUNNING;
    });
    builder.addCase(asyncThunk.fulfilled, (state: CrudFetchState, action: PayloadAction<any>) => {
        state.status = ApiStatus.COMPLETED;
        state.data = action.payload;
    });
    builder.addCase(asyncThunk.rejected, (state: CrudFetchState, action: PayloadAction<ApiError>) => {
        state.status = ApiStatus.ERROR;
        state.error = action.payload;
    });
}

const apiCreateAsyncThunk = (typePrefix: string, fn: (data: any) => Promise<void>) => {
    return createAsyncThunk(
        typePrefix,
        async (data: any, {rejectWithValue}) => {
            try {
                return await fn(data);
            } catch (err: any) {
                return rejectWithValue(err.response || err);
            }
        }
    );
}

const apiDeleteAsyncThunk = (typePrefix: string, fn: (id: string) => Promise<void>) => {
    return createAsyncThunk(
        typePrefix,
        async (id: string, {rejectWithValue}) => {
            try {
                return await fn(id);
            } catch (err: any) {
                return rejectWithValue(err.response || err);
            }
        }
    );
}

const apiGetAllAsyncThunk = (typePrefix: string, fn: (params?: any) => Promise<any>) => {
    return createAsyncThunk(
        typePrefix,
        async (params: any, {rejectWithValue}) => {
            try {
                return await fn(params);
            } catch (err: any) {
                return rejectWithValue(err.response || err);
            }
        }
    );
}

const apiGetByIdAsyncThunk = (typePrefix: string, fn: (id: string) => Promise<any>) => {
    return createAsyncThunk(
        typePrefix,
        async (id: string, {rejectWithValue}) => {
            try {
                return await fn(id);
            } catch (err: any) {
                return rejectWithValue(err.response || err);
            }
        }
    );
}

const apiUpdateAsyncThunk = (typePrefix: string, fn: (id: string, data: any) => Promise<void>) => {
    return createAsyncThunk(
        typePrefix,
        async ({id, data}: { id: string, data: any }, {rejectWithValue}) => {
            try {
                return await fn(id, data);
            } catch (err: any) {
                return rejectWithValue(err.response || err);
            }
        }
    );
}


export {
    crudApiMutationReducers, crudApiFetchReducers, apiCreateAsyncThunk, apiDeleteAsyncThunk,
    apiGetAllAsyncThunk, apiGetByIdAsyncThunk, apiUpdateAsyncThunk
};