import React from "react";
import WalletsColumns from "./WalletsTable-Columns";
import Table from "../../components/Table";
import {confirmDelete} from "../../utils/confirmDelete";
import {message} from "antd";
import {deleteWalletApi} from "../../api/entities/wallets";

type WalletsTableProps = {
    onDeleted(): void,
    onEdit(id: string): void,
    wallets: any,
    isLoading: boolean
}
const WalletsTable = (
    {onDeleted, onEdit, wallets, isLoading}: WalletsTableProps
) => {

    const onDelete = async (id: string) => {
        confirmDelete("Are you sure to delete this wallet?", async () => {
            try {
                await deleteWalletApi(id);
                message.success('Wallet deleted');
                onDeleted();
            } catch (err: any) {
                message.error(err.message);
            }
        });
    }
    const columns = WalletsColumns({onDelete, onEdit});

    return (
        <Table title="Wallets" columns={columns} data={wallets} loading={isLoading}/>
    )
}

export default WalletsTable;