import {Movement} from "../../types/Movement";
import {BiLeftDownArrowCircle, BiRightTopArrowCircle} from "react-icons/bi";
import {Space, TableProps, Tag} from "antd";
import TableUpdateButton from "../../components/TableUpdateButton";
import TableDeleteButton from "../../components/TableDeleteButton";
import React from "react";
import moment from "moment/moment";

type MovementsTableColumnsProps = {
    onDelete(id: string): void,
    onEdit(id: string): void
}

export const MovementsTableColumns = (
    {onDelete, onEdit}: MovementsTableColumnsProps
): TableProps<{}>['columns'] => {

    function convertDateFormat(inputDate: string): string {
        return moment(inputDate).format('DD/MM/YYYY');
    }


    return [
        {
            title: 'Wallet',
            key: 'wallet',
            width: 150,
            render: (movement: Movement) => {
                return movement.wallet.name;
            }
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: 100,
            render: (date: string) => {
                return convertDateFormat(date);
            }
        },
        {
            title: '',
            dataIndex: 'type',
            key: 'type',
            width: 50,
            render: (type: string) => {
                return type === 'INCOME' ?
                    <BiLeftDownArrowCircle style={{fontSize: '24px', color: 'green'}}/> :
                    <BiRightTopArrowCircle style={{fontSize: '24px', color: 'red'}}/>;
            }
        },
        {
            title: 'Description',
            key: 'description',
            width: 1000,
            render: (record: Movement) => {
                return (
                    <div>
                        <div>{record.description}</div>
                        {record.notes && <div style={{color: 'gray'}}>{record.notes}</div>}
                    </div>
                );
            }
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            align: 'right' as const,
            width: 100,
            render: (amount: number) => {
                const formattedAmount = new Intl.NumberFormat('it-IT', {
                    style: 'currency',
                    currency: 'EUR'
                }).format(amount);
                return <span style={{color: amount > 0 ? 'green' : 'red'}}>{formattedAmount}</span>;
            }
        },
        {
            title: 'Category',
            key: 'category',
            width: 150,
            render: (movement: Movement) => {
                return movement.category ? <Tag>{movement.category?.name}</Tag> : '';
            }
        },
        {
            title: '',
            width: 100,
            key: 'actions',
            render: (record: any) => (
                <Space size="middle" key={record.id}>
                    <TableUpdateButton action={() => onEdit(record.id)}
                                       record={record}
                                       dataTestId={`edit-movement-${record.id}`}
                    />
                    <TableDeleteButton record={record}
                                       action={() => onDelete(record.id)}
                                       dataTestId={`delete-movement-${record.id}`}
                    />
                </Space>
            ),
        }
    ];
}