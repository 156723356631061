import {Layout, theme} from 'antd';
import {Outlet, useNavigate} from 'react-router-dom';
import NavMenu from '../components/NavMenu';
import {MenuItem} from '../types/MenuItem';
import {useAuth} from "../context/AuthContext";


const { Header, Content, Footer, Sider } = Layout;

const layoutStyle = {
    borderRadius: 0,
    overflow: 'hidden',
    width: 'calc(100% - 8px)',
    maxWidth: 'calc(100% - 8px)'
};

const footerStyle: React.CSSProperties = {
    textAlign: 'center',
    backgroundColor: '#001529',
    color: '#fff',
    width: '100%',
    bottom: 0,
    position: 'fixed',
};

const siderStyle: React.CSSProperties = {
    textAlign: 'center',
    lineHeight: '120px',
    color: '#fff',
    backgroundColor: '#001529',
    position: 'fixed',
    height: '100vh',
    marginTop: 64,
    padding: 5
};

const contentStyle: React.CSSProperties = {
    marginTop: 64,
    marginLeft: 200,
    minHeight: '100%',
    lineHeight: '120px',
    height: 'calc(100% - 8px)',
    maxHeight: 'calc(100% - 8px)'
};

const headerStyle: React.CSSProperties = {
    textAlign: 'center',
    color: '#fff',
    height: 64,
    paddingInline: 48,
    lineHeight: '64px',
    position: 'fixed',
    width: '100%',
    zIndex: 1
};

export default function LayoutPage() {
    const navigate = useNavigate();
    const {currentUser, logout} = useAuth();
    const {
        token: {colorBgContainer},
    } = theme.useToken();

    const onClick = (e: MenuItem) => {
        navigate(e.link);
    };

    return (
        <>
            <Layout style={layoutStyle}>
                <Header style={headerStyle}>
                    Header
                    {currentUser && (
                        <button onClick={logout} style={{marginLeft: '20px'}}>Logout</button>
                    )}
                </Header>
                <Layout>
                    <Sider style={siderStyle} theme='dark' >
                        <div className="demo-logo-vertical" />
                        <NavMenu onClick={onClick} />
                    </Sider>
                    <Content style={{ ...contentStyle, background: colorBgContainer, padding: 24 }}>
                        <Outlet />
                    </Content>
                </Layout>
                <Footer style={footerStyle}>Footer</Footer>
            </Layout>
        </>

    )

}