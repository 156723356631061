import CategoryForm from "./CategoryForm";
import React from "react";
import {createCategory, initCreateCategory} from "../../features/categories/create";
import {initUpdateCategory, updateCategory} from "../../features/categories/update";
import {useAppAsyncDispatch} from "../../hooks/useAppAsyncDispatch";
import {Modal} from "antd";
import useModalCreateOrUpdate from "../../hooks/useModalCreateOrUpdate";
import {RootState} from "../../store/store";
import {Category} from "../../types/Category";

type CategoriesModalProps = {
    isOpen: boolean,
    editItem: Category | null,
    onCancel: () => void,
    onCompleted: () => void
}
const CategoriesModal = (
    {
        isOpen,
        editItem,
        onCancel,
        onCompleted
    }: CategoriesModalProps
) => {
    const dispatch = useAppAsyncDispatch();
    const dispatchCreateCategory = (data: any) => {
        dispatch(createCategory(data))
    }
    const dispatchUpdateCategory = (id: string, category: Category) => {
        dispatch(updateCategory({id, data: category}))
    }

    useModalCreateOrUpdate(
        (state: RootState) => state.createCategory.status,
        (state: RootState) => state.createCategory.error,
        initCreateCategory,
        onCompleted,
        'Category created'
    );

    useModalCreateOrUpdate(
        (state: RootState) => state.updateCategory.status,
        (state: RootState) => state.updateCategory.error,
        initUpdateCategory,
        onCompleted,
        'Category updated'
    );

    return (
        <Modal open={isOpen}
               closable={false}
               footer={null}
               title={editItem ? 'Update category' : 'Create a new category'}>
            <CategoryForm onClose={onCancel}
                          onCreate={dispatchCreateCategory}
                          onUpdate={dispatchUpdateCategory}
                          item={editItem}/>
        </Modal>
    )

}

export default CategoriesModal;