export enum WalletType {
    CASH = 'CASH',
    CREDIT_CARD = 'CREDIT_CARD',
    BANK = 'BANK'
}

export type Wallet = {
    id: string | null;
    type: WalletType;
    name: string;
    balance: number;
    startingBalance: number;
    movementsCount: number;
}