// Initialize the FirebaseUI Widget using Firebase.
// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {browserLocalPersistence, getAuth, setPersistence} from "firebase/auth";


const firebaseConfig = {
    apiKey: "AIzaSyDbmhKp328Adq15f8XIKMm2UiaYRI0Whpk",
    authDomain: "family-manager-app-987b3.firebaseapp.com",
    projectId: "family-manager-app-987b3",
    storageBucket: "family-manager-app-987b3.appspot.com",
    messagingSenderId: "688269711454",
    appId: "1:688269711454:web:719f2ded1888a420fffccf",
    measurementId: "G-WBREPMT4N9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth();
setPersistence(auth, browserLocalPersistence)
    .then(() => {
        // Existing and future Auth states are now persisted in the current
        // session only. Closing the window would clear any existing state even
        // if a user forgets to sign out.
        // ...
        // New sign-in will be persisted with session persistence.
    })
    .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
    });

export {auth};
export default app;