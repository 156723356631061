import {TableProps} from "antd";

export const ExpensesTableColumns = (): TableProps<{}>['columns'] => {
    return [
        {
            title: 'Category',
            dataIndex: 'categoryName',
            key: 'categoryName',
            render: (text: string | null) => {
                return text ?? 'Uncategorized';
            }
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            align: 'right' as const,
            render: (amount: number) => {
                return new Intl.NumberFormat('it-IT', {
                    style: 'currency',
                    currency: 'EUR'
                }).format(amount);
            }
        }
    ];
}