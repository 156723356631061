import React, {PropsWithChildren} from "react";
import {Card, Table as AntTable, TableProps} from "antd";

type CrudComponentTableProps = PropsWithChildren & {
    columns: TableProps<{}>['columns'];
    title: string;
    data: any[];
    loading: boolean;
    pagination?: any;
    rowSelection?: any;
};

const Table = function CrudComponentTable(
    {
        columns,
        title,
        data,
        loading,
        pagination = false,
        rowSelection,
        children
    }: CrudComponentTableProps) {

    return (
        <Card title={title}>
            {children}
            <AntTable dataSource={data} columns={columns}
                      rowSelection={rowSelection}
                      pagination={pagination}
                      rowKey="id" loading={loading}/>
        </Card>
    );
}

export default Table;