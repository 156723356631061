import React from 'react';
import {Button, Card, Form, Input, Typography} from 'antd';
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import './Login.css';
import {signInWithEmailAndPassword} from 'firebase/auth';
import {auth} from "../../firebase";
import {useAuth} from "../../context/AuthContext";

const LoginPage: React.FC = () => {
    const {loading} = useAuth();
    const onFinish = async (values: any) => {
        console.log('Received values of form: ', values);
        try {
            await signInWithEmailAndPassword(auth, values.username, values.password);
            if (!loading)
                window.location.href = '/';
            console.log('User logged in');
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <div className="login-container">
            <Card className="login-card">
                <Typography.Title level={2} className="login-title">Family Manager Login</Typography.Title>

                <Form
                    name="login"
                    initialValues={{remember: true}}
                    labelCol={{span: 24}}
                    wrapperCol={{span: 24}}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="username"
                        label={<span>Username</span>}
                        rules={[{required: true, message: 'Please input your Username!'}]}
                    >
                        <Input prefix={<UserOutlined/>} placeholder="Username"/>
                    </Form.Item>
                    <Form.Item
                        name="password"
                        label={<span>Password</span>}
                        rules={[{required: true, message: 'Please input your Password!'}]}
                    >
                        <Input.Password prefix={<LockOutlined/>} placeholder="Password"/>
                    </Form.Item>
                    <Form.Item className="login-form-button-container">
                        <Button type="primary" htmlType="submit" className="login-form-button" style={{width: 200}}>
                            Log in
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
};

export default LoginPage;