import {Menu, theme} from "antd";
import {MenuItem} from "../types/MenuItem";
import {BarsOutlined, HomeOutlined, PieChartOutlined, TagOutlined, WalletOutlined} from "@ant-design/icons";
import React from "react";

const menuItems: MenuItem[] = [
    {
        key: "0",
        label: "Home",
        link: "/",
        icon: <HomeOutlined />
    },
    {
        key: "wallets",
        label: "Wallets",
        link: "/wallets",
        icon: <WalletOutlined />
    },
    {
        key: "movements",
        label: "Movements",
        link: "/movements",
        icon: <BarsOutlined/>
    },
    {
        key: "categories",
        label: "Categories",
        link: "/categories",
        icon: <TagOutlined/>
    },
    {
        key: "expenses",
        label: "Expenses",
        link: "/expenses",
        icon: <PieChartOutlined/>
    }
];

type NavMenuProps = {
    onClick: (menuItem: MenuItem) => void;
}

export default function NavMenu({ onClick }: NavMenuProps) {

    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    function handleClick(e: any): void {
        const menuItem = menuItems.find((item) => item.key === e.key);
        if (!menuItem) return;
        onClick(menuItem);
    }

    return (
        <Menu onClick={(e) => handleClick(e)}
            theme="dark" mode="inline"
            defaultSelectedKeys={['0']}
            items={menuItems} />
    )
}