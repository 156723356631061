import Table from "../../components/Table";
import React from "react";
import {ExpensesTableColumns} from "./ExpensesTable-Columns";

type ExpensesTableProps = {
    isLoading: boolean,
    data: any
}
export const ExpensesTable = (
    {isLoading, data}: ExpensesTableProps
) => {
    const columns = ExpensesTableColumns();

    return (
        <Table title="Expenses" columns={columns} data={data} loading={isLoading}>
        </Table>
    )
}