import React from "react";
import {createWallet, initCreateWallet} from "../../features/wallets/create";
import {initUpdateWallet, updateWallet} from "../../features/wallets/update";
import {useAppAsyncDispatch} from "../../hooks/useAppAsyncDispatch";
import {Modal} from "antd";
import {Wallet} from "../../types/Wallet";
import WalletForm from "./WalletForm";
import useModalCreateOrUpdate from "../../hooks/useModalCreateOrUpdate";
import {RootState} from "../../store/store";


type WalletsModalProps = {
    isOpen: boolean,
    editItem: Wallet | null,
    onCancel: () => void,
    onCompleted: () => void
}
const WalletsModal = (
    {
        isOpen,
        editItem,
        onCancel,
        onCompleted
    }: WalletsModalProps
) => {
    const dispatch = useAppAsyncDispatch();
    const dispatchCreateWallet = (data: any) => {
        dispatch(createWallet(data))
    }
    const dispatchUpdateWallet = (id: string, wallet: Wallet) => {
        dispatch(updateWallet({id, data: wallet}))
    }

    useModalCreateOrUpdate(
        (state: RootState) => state.createWallet.status,
        (state: RootState) => state.createWallet.error,
        initCreateWallet,
        onCompleted,
        'Wallet created'
    );

    useModalCreateOrUpdate(
        (state: RootState) => state.updateWallet.status,
        (state: RootState) => state.updateWallet.error,
        initUpdateWallet,
        onCompleted,
        'Wallet updated'
    );

    return (
        <Modal open={isOpen}
               closable={false}
               footer={null}
               title={editItem ? 'Update wallet' : 'Create a new wallet'}>
            <WalletForm onClose={onCancel}
                        onCreate={dispatchCreateWallet}
                        onUpdate={dispatchUpdateWallet}
                        item={editItem}/>
        </Modal>
    )
}

export default WalletsModal;