import React from "react";
import {Modal} from "antd";
import MovementsChangeCategoryForm from "./MovementsChangeCategoryForm";

type MovementsChangeCategoryModalProps = {
    ids: string[],
    isOpen: boolean,
    onCancel: () => void,
    onCompleted: () => void
}
const MovementsChangeCategoryModal = (
    {
        ids,
        isOpen,
        onCancel,
        onCompleted
    }: MovementsChangeCategoryModalProps) => {

    return (
        <Modal open={isOpen}
               closable={false}
               footer={null}
               title={'Change category'}>
            <MovementsChangeCategoryForm onCompleted={onCompleted} onClose={onCancel} ids={ids}/>
        </Modal>
    )
}

export default MovementsChangeCategoryModal;