import {EditOutlined} from "@ant-design/icons";
import React from "react";
import TableButton, {FMTableActionProps} from "./TableButton";

const TableUpdateButton = ({record, action, dataTestId}: FMTableActionProps) => {
    return (
        <TableButton record={record}
                     action={action}
                     dataTestId={dataTestId}
                     icon={<EditOutlined style={{fontSize: '16px', color: '#666666'}}/>}

        />
    )
}

export default TableUpdateButton;