import React from "react";
import {MovementsTableColumns} from "./MovementsTable-Columns";
import Table from "../../components/Table";
import MovementsFilters from "./MovementsFilters";
import {confirmDelete} from "../../utils/confirmDelete";
import {deleteMovementApi} from "../../api/entities/movements";
import {message} from "antd";

type MovementsTableProps = {
    onDeleted(): void,
    onEdit(id: string): void,
    onChange(changes: any): void,
    pagedMovements: any,
    isLoading: boolean,
    rowSelection: any
}
const MovementsTable = (
    {onDeleted, onEdit, onChange, pagedMovements, isLoading, rowSelection}: MovementsTableProps
) => {
    const onDelete = async (id: string) => {
        confirmDelete("Are you sure to delete this movement?", async () => {
            try {
                await deleteMovementApi(id);
                message.success('Movement deleted');
                onDeleted();
            } catch (err: any) {
                message.error(err.message);
            }
        });
    }

    const columns = MovementsTableColumns({onDelete, onEdit});

    const onPaginationChange = (page: number, pageSize?: number) => {
        onChange({page: page, pageSize: pageSize});
    }

    const onFilterChange = (changes: any) => {
        onChange({...changes, page: 1});
    }

    return (
        <Table title="Movements" columns={columns} data={pagedMovements.data} loading={isLoading}
               pagination={{
                   total: pagedMovements?.total,
                   current: pagedMovements?.page,
                   pageSize: pagedMovements?.pageSize,
                   pageSizeOptions: ['5', '10', '20', '50', '100'],
                   showSizeChanger: true,
                   onChange: onPaginationChange
               }}
               rowSelection={rowSelection}
        >
            <MovementsFilters onChange={onFilterChange}/>
        </Table>

    )
}

export default MovementsTable;