import fetchWalletsReducer from "./getAll";
import createWalletReducer from "./create";
import updateWalletReducer from "./update";
import deleteWalletReducer from "./delete";
import crudWalletsReducer from "./crud";

export const getWalletsReducers = () => ({
    fetchWallets: fetchWalletsReducer,
    createWallet: createWalletReducer,
    updateWallet: updateWalletReducer,
    deleteWallet: deleteWalletReducer,
    crudWallet: crudWalletsReducer
});