import {Modal} from "antd";

export const confirmDelete = (title: string, deleteAction: () => void) => {
    Modal.confirm({
        title: title,
        content: 'This action cannot be undone',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: () => {
            deleteAction();
        }
    });

}