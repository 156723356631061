import React from "react";
import {Space, TableProps} from "antd";
import TableDeleteButton from "../../components/TableDeleteButton";
import TableUpdateButton from "../../components/TableUpdateButton";

type CategoriesTableColumnsProps = {
    onDelete(id: string): void,
    onEdit(id: string): void
}
const CategoryColumns = (
    {onDelete, onEdit}: CategoriesTableColumnsProps
): TableProps<{}>['columns'] => {

    return [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '',
            width: 100,
            key: 'action-delete',
            render: (record: any) => (
                <Space size="middle">
                    <TableUpdateButton
                        action={() => onEdit(record.id)}
                        record={record}
                        dataTestId={`edit-category-${record.id}`}
                    />
                    <TableDeleteButton
                        record={record}
                        action={() => onDelete(record.id)}
                        dataTestId={`delete-category-${record.id}`}
                    />
                </Space>
            ),
        }
    ];
}


export default CategoryColumns;