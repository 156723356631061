import ImportMovementsForm from "./ImportMovementsForm";
import {message, Modal} from "antd";
import React from "react";
import {uploadExcelApi} from "../../api/entities/movements";

type ImportMovementModalProps = {
    isOpen: boolean;
    onCancel: () => void;
    onCompleted: () => void;
}
export const ImportMovementModal = ({isOpen, onCancel, onCompleted}: ImportMovementModalProps) => {

    const handleConfirm = async (data: any) => {
        const formData = new FormData();
        formData.append('walletId', data.walletId);
        formData.append('file', data.excelFile.file);
        try {
            await uploadExcelApi(formData);
            message.success('Movements imported successfully');
            onCompleted();
        } catch (error) {
            message.error('An error occurred while importing movements');
        }
    }

    return (
        <Modal
            title="Upload movements excel file"
            open={isOpen}
            footer={null}
            closable={false}
        >
            <ImportMovementsForm onConfirm={handleConfirm} onCancel={onCancel}/>
        </Modal>
    )
}

