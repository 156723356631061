import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    apiCreateAsyncThunk,
    apiDeleteAsyncThunk,
    apiGetAllAsyncThunk,
    apiGetByIdAsyncThunk,
    apiUpdateAsyncThunk,
    crudApiFetchReducers,
    crudApiMutationReducers
} from "./asyncThunkExtraReducers";
import {ApiStatus} from "../types/ApiStatus";
import {CrudFetchState} from "../types/CrudFetchState";
import {Filter} from "../types/Filter";
import {CrudMutationState} from "../types/CrudMutationState";

export type CrudState = {
    isOpen: boolean;
    item?: any;
    title: string;
    error?: any | undefined;
};

const createCrudSlice = (name: string,
                         editTitle: string,
                         createTitle: string,
                         getEntityApi: (id: string) => Promise<any>) => {
    const openModalForEdit = apiGetByIdAsyncThunk(`${name}/openModalForEdit`, getEntityApi);

    const slice = createSlice({
        name,
        initialState: {
            isOpen: false,
            item: undefined,
            title: ''
        } as CrudState,
        reducers: {
            reset(state) {
                state.isOpen = false;
                state.item = undefined;
                state.error = undefined;
            },
            openModalForCreate(state) {
                state.isOpen = true;
                state.item = null;
                state.title = createTitle;
            },
            closeModal(state) {
                state.isOpen = false;
                state.item = undefined;
            },
            setItem(state, action: PayloadAction<any>) {
                state.item = action.payload;
            }
        },
        extraReducers: (builder) => {
            builder.addCase(openModalForEdit.fulfilled, (state, action: PayloadAction<any>) => {
                state.item = action.payload;
                state.title = editTitle;
                state.isOpen = true;
                state.error = undefined;
            });
            builder.addCase(openModalForEdit.rejected, (state, action) => {
                state.isOpen = false;
                state.item = undefined;
                state.error = action.payload;
            });
        }
    });

    return {
        actions: slice.actions,
        reducer: slice.reducer,
        openModalForEdit
    };
};


const createFetchAllSlice = (name: string, fetchAllEntitiesApi: (params?: any | undefined) => Promise<any>) => {
    const fetchAllEntities = apiGetAllAsyncThunk(`${name}/fetchAll`, fetchAllEntitiesApi);

    const slice = createSlice({
        name: `${name}/fetchAll`,
        initialState: {
            data: [],
            status: ApiStatus.IDLE,
            error: undefined
        } as CrudFetchState,
        reducers: {
            init(state) {
                state.data = [];
                state.status = ApiStatus.IDLE;
                state.error = undefined
            },
            setFilter(state, action: PayloadAction<Filter[]>) {
                state.filters = action.payload;
            }
        },
        extraReducers: (builder) => crudApiFetchReducers(builder, fetchAllEntities)
    });

    return {
        actions: slice.actions,
        reducer: slice.reducer,
        fetchAllEntities
    };
}

const createDeleteSlice = (name: string, deleteEntityApi: (id: string) => Promise<any>) => {
    const deleteEntity = apiDeleteAsyncThunk(`${name}/delete`, deleteEntityApi);

    const slice = createSlice({
        name: `${name}/delete`,
        initialState: {
            status: ApiStatus.IDLE,
            error: undefined
        },
        reducers: {
            init(state) {
                state.status = ApiStatus.IDLE;
                state.error = undefined;
            },
        },
        extraReducers: (builder) => crudApiMutationReducers(builder, deleteEntity)
    });

    return {
        actions: slice.actions,
        reducer: slice.reducer,
        deleteEntity
    };
}

const createAddSlice = (name: string, addEntityApi: (data: any) => Promise<any>) => {
    const addEntity = apiCreateAsyncThunk(`${name}/create`, addEntityApi);

    const slice = createSlice({
        name: `${name}/create`,
        initialState: {
            status: ApiStatus.IDLE,
            error: undefined
        } as CrudMutationState,
        reducers: {
            init(state) {
                state.status = ApiStatus.IDLE;
                state.error = undefined;
            },
        },
        extraReducers: (builder) => crudApiMutationReducers(builder, addEntity)
    });

    return {
        actions: slice.actions,
        reducer: slice.reducer,
        addEntity
    };
}

const createUpdateSlice = (name: string, updateEntityApi: (id: string, data: any) => Promise<any>) => {
    const updateEntity = apiUpdateAsyncThunk(`${name}/update`, updateEntityApi);

    const slice = createSlice({
        name: `${name}/update`,
        initialState: {
            status: ApiStatus.IDLE,
            error: undefined
        },
        reducers: {
            init(state) {
                state.status = ApiStatus.IDLE;
                state.error = undefined;
            },
        },
        extraReducers: (builder) => crudApiMutationReducers(builder, updateEntity)
    });

    return {
        actions: slice.actions,
        reducer: slice.reducer,
        updateEntity
    };
}

export {createCrudSlice, createFetchAllSlice, createDeleteSlice, createAddSlice, createUpdateSlice};