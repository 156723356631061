/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import WalletsTable from "./WalletsTable";
import {Button, message} from "antd";
import Toolbar from "../../components/Toolbar";
import {useAppAsyncDispatch} from "../../hooks/useAppAsyncDispatch";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {ApiStatus} from "../../types/ApiStatus";
import {getWalletApi} from "../../api/entities/wallets";
import {fetchWallets} from "../../features/wallets/getAll";
import WalletsModal from "./WalletsModal";


const WalletsPage = () => {

    const dispatch = useAppAsyncDispatch()
    const [isOpen, setIsOpen] = useState(false);
    const [item, setItem] = useState<any>(null);
    const isLoading = useSelector((state: RootState) => state.fetchWallets.status === ApiStatus.RUNNING);
    const wallets = useSelector((state: RootState) => state.fetchWallets.data);
    const fetch = () => dispatch(fetchWallets({},));
    const openModalForEdit = async (id: string) => {
        try {
            const wallet = await getWalletApi(id);
            setItem(wallet);
            setIsOpen(true);
        } catch (err: any) {
            message.error(err.message);
        }
    }
    const openModalForCreate = () => {
        setItem(null);
        setIsOpen(true);
    }
    const closeModal = () => {
        setItem(null);
        setIsOpen(false);
    }
    const onCompleted = () => {
        closeModal();
        fetch();
    }

    useEffect(() => {
        fetch();
    }, []);

    return (
        <div>
            <Toolbar>
                <Button type="primary" data-testId="button-create-new"
                        onClick={openModalForCreate}>New Wallet</Button>
            </Toolbar>
            <WalletsTable onEdit={openModalForEdit} onDeleted={onCompleted} wallets={wallets} isLoading={isLoading}/>
            <WalletsModal isOpen={isOpen} editItem={item} onCancel={closeModal} onCompleted={onCompleted}/>
        </div>
    )
}


export default WalletsPage;