import {expensesRoute} from "../constants";
import {getApi} from "../get";
import {ExpensesGroupByCategory} from "../../types/ExpensesGroupByCategory";

export const getExpensesGroupByCategoryApi = async (params?: any): Promise<ExpensesGroupByCategory[]> => {
    console.log('getExpensesGroupByCategoryApi', params);
    return await getApi(expensesRoute, params);
}

// export const addCategoryApi = async (category: Category): Promise<void> => {
//     return await postApi(categoriesRoute, category);
// }
//
// export const deleteCategoryApi = async (id: string): Promise<void> => {
//     return await deleteApi(`${categoriesRoute}/${id}`);
// }
//
// export const getCategoryApi = async (id: string): Promise<Category> => {
//     return await getApi(`${categoriesRoute}/${id}`);
// }
//
// export const updateCategoryApi = async (id: string, category: Category): Promise<void> => {
//     return await putApi(categoriesRoute, id, category);
// }

