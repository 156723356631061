import categoriesFetchReducer from "./getAll";
import categoryCreation from "./create";
import categoryUpdate from "./update";
import categoryDelete from "./delete";
import categoriesCrudReducer from "./crud";

export const getCategoryReducers = () => ({
    fetchCategories: categoriesFetchReducer,
    createCategory: categoryCreation,
    updateCategory: categoryUpdate,
    deleteCategory: categoryDelete,
    categoriesCrud: categoriesCrudReducer
});