import {Input, Space} from "antd";
import React from "react";
import {debounce} from "lodash";

type CategoriesFiltersProps = {
    onChange: (changes: any) => void
}
const CategoriesFilters = (
    {onChange}: CategoriesFiltersProps
) => {

    const onNameFilterChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        onChange({name: e.target.value});
    }, 500);

    return (
        <Space style={{marginBottom: '20px', justifyContent: 'end', width: '100%'}}>
            <Space style={{justifyContent: 'end'}}>
                <Input data-testid='filter-category-name'
                       style={{marginRight: '20px', minWidth: '100px'}}
                       placeholder='Filter by name'
                       onChange={onNameFilterChange}/>
            </Space>
        </Space>
    )
}

export default CategoriesFilters;